<template>
  <v-footer class="blue-grey darken-3 theme--dark">
    <v-col>
      <v-row class="ma-lg-6 justify-lg-center">
        <v-col cols="6" lg="2" md="3">
          <div class="title mb-2 text-truncate">Navigatie</div>
          <router-link class="d-block white--text text-decoration-none" v-for="(item, index) in menu" :key="index" :to="item.to">{{ item.name }}</router-link>
        </v-col>
        <v-col cols="6" lg="2" md="3">
          <div class="title mb-2 text-truncate">Juridisch</div>
          <div>Algemene voorwaarden</div>
          <div>Privacy verklaring</div>
          <div>KVK: 80247776</div>
          <div>IBAN: NL69 INGB 0006 4697 45</div>
        </v-col>
        <v-col cols="6" lg="2" md="3">
          <div class="title mb-2 text-truncate">Openingstijden</div>
          <div><div style="min-width:30px" class="d-inline-block">Ma </div>08:30 - 18:00</div>
          <div><div style="min-width:30px" class="d-inline-block">Di </div>08:30 - 20:00</div>
          <div><div style="min-width:30px" class="d-inline-block">Wo </div>08:30 - 16:00</div>
          <div><div style="min-width:30px" class="d-inline-block">Do </div>08:30 - 20:00</div>
          <div><div style="min-width:30px" class="d-inline-block">Vr </div>08:30 - 12:00</div>
        </v-col>
        <v-col cols="6" lg="2" md="3">
          <div class="title mb-2 text-truncate">Contactgegevens</div>
          <div>Centrum voor Houding en Beweging</div>
          <a class="white--text d-block text-decoration-none"
             href="https://maps.google.com/?q=Dukaat 1a, 5283 PJ Boxtel"
             rel="noopener noreferrer"
             target="_blank">Dukaat 1a, 5283 PJ Boxtel
          </a>
          <a class="white--text d-block text-decoration-none"
             href="tel:0411 67 14 56"
             rel="noopener noreferrer"
             target="_blank">0411 67 14 56
          </a>
          <a class="white--text d-block text-decoration-none"
             href="mailto:info@mensendieckboxtel.nl"
             rel="noopener noreferrer"
             target="_blank">info@mensendieckboxtel.nl
          </a>
        </v-col>
      </v-row>
      <v-row class="text-center mt-12" justify="center">
        <a href="https://vvocm.nl/" rel="noreferrer noopener" target="_blank">
          <img :src="require('@/assets/images/logo_vvcm.png')" alt="logo vvcm" class="certified-logo mx-6"/>
        </a>
        <a href="https://www.kwaliteitsregisterparamedici.nl/" rel="noreferrer noopener" target="_blank">
          <img :src="require('@/assets/images/logo-kp.png')" alt="logo kp" class="certified-logo mx-6"/>
        </a>
        <a href="https://www.mszorgnederland.nl/" rel="noreferrer noopener" target="_blank">
          <img :src="require('@/assets/images/logo-ms.png')" alt="logo ms" class="certified-logo mx-6"/>
        </a>
        <a href="https://www.slaapoefentherapie.nl/" rel="noreferrer noopener" target="_blank">
          <img :src="require('@/assets/images/logo-slaapoefentherapie.png')"
               alt="logo slaapoefentherapie"
               class="certified-logo mx-6"/>
        </a>
        <a href="https://www.artrose-netwerk.nl/" rel="noreferrer noopener" target="_blank">
          <img :src="require('@/assets/images/logo_artrose-netwerk.png')"
               alt="logo slaapoefentherapie"
               class="certified-logo mx-6"/>
        </a>
      </v-row>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    menu: {
      required: true,
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-footer {
  overflow: hidden;
  word-break: break-word;
  white-space: pre-wrap;

  .certified-logo {
    filter: brightness(0) invert(1);
    max-height: 75px;
    max-width: 80%;
  }
}
</style>
