<template>
  <v-row v-if="$vuetify.breakpoint.smAndDown" class="px-6 fill-height" style="background: rgba(0,0,0,0.2)">

    <VAppBarNavIcon class="navBarIcon" dark/>
    <v-col class="text-center" cols="12">
      <img :src="require('@/assets/logo.svg')" alt="logo" class="mt-16 pt-16" width="100%"/>
      <v-fade-transition>
        <h1 v-if="quote" class="text-h4 white--text mt-10">{{ quote }}</h1>
      </v-fade-transition>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'HeroMobileContents',
  props: {
    quote: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.navBarIcon {
  left:     15px;
  position: fixed;
  top:      15px;
}
</style>
