<template>
  <v-img :lazy-src="require('@/assets/images/hero_image_lazy.jpg')"
         :src="require('@/assets/images/hero_image_full_hd.jpg')"
         class="header-image"
         position="left"
  >
    <HeroMobileContents :quote="randomQuote"/>
    <HeroDesktopContents :menu="menu" :quote="randomQuote"/>
  </v-img>
</template>

<script>
import HeroMobileContents from '@/components/layout/HeroMobileContents.vue';
import HeroDesktopContents from '@/components/layout/HeroDesktopContents.vue';

export default {
  name: 'Hero',
  components: {
    HeroDesktopContents,
    HeroMobileContents,
  },
  data() {
    return {
      scrollTop: false,
      heroTexts: [
        'Het Mensendieck effect.',
        'Verbeter je kwaliteit van leven met Mensendieck',
        'Denk... voel... ervaar... Mensendieck',
        'Verander jezelf met Mensendieck',
        'Samenwerken aan je herstel met Mensendieck',
        'Mensendieck…onmogelijk bestaat niet.',
        'Word je eigen therapeut met Mensendieck',
        'Met Mensendieck geef ik je de sleutel tot jouw verandering',
        'Hoe ziet jouw houding eruit... Mensendieck',
        'Mensendieck…een beetje van mij, zoveel van jezelf…',
        'Mensendieck…, aangenaam…',
        'Als het eruitziet als Mensendieck, ruikt als Mensendieck en klinkt als Mensendieck, dan is het waarschijnlijk Mensendieck.',
      ],
      interval: 0,
      randomNumber: undefined,
    };
  },
  props: {
    menu: {
      required: true,
      type: Array,
    },
  },
  computed: {
    randomQuote() {
      if (!this.randomNumber) return '';
      return this.heroTexts[this.randomNumber];
    },
  },
  created() {
    this.randomNumber = Math.floor(Math.random() * this.heroTexts.length + 1);

    this.interval = setInterval(() => {
      this.randomNumber = undefined;
      setTimeout(() => {
        this.randomNumber = Math.floor(Math.random() * this.heroTexts.length + 1);
      }, 1000);
    }, 9000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss">
.header-image {
  height: 80vh;
}
</style>
