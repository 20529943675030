import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import nl from 'vuetify/lib/locale/nl';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { nl },
    current: 'nl',
  },
  theme: {
    themes: {
      light: {
        primary: '#63B8E8',
        secondary: '#c8f4f3',
      },
    },
  },
});
