<template>
  <div v-if="$vuetify.breakpoint.mdAndUp">
    <v-row class="justify-space-between align-start mx-6">
      <div class="d-flex">
        <img :src="require('@/assets/logo.svg')" alt="logo" class="mt-5" width="500px"/>
      </div>
      <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex menuu align-self-start just">
        <v-btn v-for="(item, index) in menu" :key="index" :to="item.to" dark exact text>{{ item.name }}</v-btn>
      </div>
    </v-row>
    <v-fade-transition>
      <div v-if="quote" class="quote pa-6">
        <h1 class="text-h3 white--text text-right">{{ quote }}</h1>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  name: 'HeroDesktopContents',
  props: {
    menu: {
      required: true,
      type: Array,
    },
    quote: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.quote {
  background: rgba(0, 0, 0, 0.2);
  bottom:     50px;
  position:   absolute;
  right:      50px;
}

.menuu {
  position: fixed;
  right:    15px;
  top:      25px;
}
</style>
