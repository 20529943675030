<template>
  <v-app>
    <v-app-bar
        fixed
        color="primary darken-3"
        dark
        :value="showMenu"
    >
      <VAppBarNavIcon v-if="$vuetify.breakpoint.smAndDown" @click="showDrawer = true"/>
      <VSpacer/>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn dark text v-for="(item, index) in menu" :key="index" :to="item.to" exact>{{ item.name }}</v-btn>
      </template>
    </v-app-bar>
    <v-navigation-drawer app temporary v-model="showDrawer">
      <img :src="require('@/assets/logo.svg')" alt="logo" width="100%" class="mt-8 pr-3"/>
      <v-list nav>
        <v-list-item v-for="(item, index) in menu" :to="item.to" :key="index" exact>
          {{item.name}}
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <Hero :menu="menu" id="home"/>
      <router-view></router-view>
    </v-main>
      <Footer :menu="menu"/>
  </v-app>
</template>

<script>
import './scss/main.scss';
import Hero from '@/components/layout/Hero.vue';
import Footer from '@/components/layout/Footer.vue';

export default {
  name: 'App',
  components: { Footer, Hero },
  data() {
    return {
      showMenu: false,
      showDrawer: false,
      menu: [
        {
          name: 'Home',
          to: {name: 'home'}
        },
        {
          name: 'Behandelingen',
          to: {name: 'treatments'}
        },
        {
          name: 'Praktijk',
          to: {name: 'practice'}
        },
        {
          name: 'Over mij',
          to: {name: 'who-am-i'}
        },
        {
          name: 'Contact',
          to: {name: 'contact'}
        },
      ],
    };
  },
  computed: {
    scrollTreshold() {
      const percentage = this.$route.name === 'Home' ? 0.45 : 0.2;
      return window.innerHeight * percentage;
    },
  },
  created() {
    window.addEventListener('scroll', this.onScroll);
    this.onScroll();
  },
  methods: {
    onScroll() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.showMenu = true;
        return;
      }

      if (window.scrollY > window.innerHeight * 0.4) {
        this.showMenu = true;
        return;
      }
      this.showMenu = false;
    },
  },
};
</script>
